import { autoinject, bindable } from 'aurelia-framework';
import { PubSub } from '../lib/event/PubSub';
import './drawer.scss';

@autoinject()
export class Drawer {
  @bindable
  protected name = '';

  protected visible = false;

  constructor(private pubsub: PubSub) {}

  attached() {
    if (!this.name) {
      throw new Error('Drawer must have a name');
    }

    this.pubsub.sub('drawer:open', (name: string) => {
      if (name === this.name) {
        this.visible = true;
      }
    });

    this.pubsub.sub('drawer:close', (name: string) => {
      if (name === this.name) {
        this.visible = false;
      }
    });
  }

  protected doClose() {
    this.pubsub.publish('drawer:close', this.name);
    this.visible = false;
  }

  unbind() {
    this.pubsub.unsub();
  }

  detached() {
    this.pubsub.unsub();
  }
}
